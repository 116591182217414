import { useState, useEffect } from "react";
import { useRouter } from "next/router";

import { Toast, ToastContainer } from "react-bootstrap";
import { alertService } from "src/services";
import { AlertObj } from "src/helpers";

const Alert = () => {
  const router = useRouter();
  const [alert, setAlert] = useState<AlertObj | null>();

  useEffect(() => {
    // subscribe to new alert notifications
    const subscription = alertService.alert.subscribe((alert) =>
      setAlert(alert),
    );

    // unsubscribe when the component unmounts
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    // clear alert on location change
    alertService.clear();
  }, [router]);

  setTimeout(() => {
    alertService.clear();
  }, 5000);

  if (!alert) return null;

  return (
    <ToastContainer
      className="position-fixed top-0 start-50 translate-middle-x p-3"
      style={{ zIndex: 2000 }}
    >
      <Toast bg={alert.type} animation={false}>
        <Toast.Header>
          <strong className="me-auto">Notification</strong>
        </Toast.Header>
        <Toast.Body className="text-white">{alert.message}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};
export { Alert };
