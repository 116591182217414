import { BehaviorSubject } from "rxjs";
import Router from "next/router";
import { UserObject, fetchWrapper } from "src/helpers";
import { alertService } from "src/services";
import { graphqlApiCall } from "./graphql.service";

const baseUrl = "/api/users";
const userSubject = new BehaviorSubject(
  typeof window !== "undefined" &&
    localStorage.getItem("user") !== null &&
    JSON.parse(localStorage.getItem("user") || ""),
);

const columns = ["id", "firstName", "lastName", "username", "isAdmin"];

const login = async (
  username: string,
  password: string,
  isRemember: boolean,
) => {
  const user = await fetchWrapper.post(`${baseUrl}/authenticate`, {
    firstName: "",
    lastName: "",
    username,
    password,
  });

  // publish user to subscribers and store in local storage to stay logged in between page refreshes
  userSubject.next(user);
  isRemember ? localStorage.setItem("user", JSON.stringify(user)) : "";
};

const logout = () => {
  alertService.clear();
  // remove user from local storage, publish null to user subscribers and redirect to login page
  localStorage.removeItem("user");
  userSubject.next(null);
  Router.push("/account/login");
};

const register = async (user: UserObject) => {
  await fetchWrapper.post(`${baseUrl}/register`, user);
};

const getAll = async () => {
  const response = await graphqlApiCall({ node: "allAdmins", columns });
  return response.map((data: Record<string, string>) => {
    return {
      ...data,
      is_admin: data.isAdmin,
    };
  });
};

const getById = async (id: string) => {
  const response: UserObject = await graphqlApiCall({
    node: "adminById",
    columns,
    id,
  });
  return response;
};

const update = async (id: string, params: UserObject) => {
  await fetchWrapper.put(`${baseUrl}/${id}`, params);

  // update stored user if the logged in user updated their own record
  if (id === userSubject.value.id) {
    // update local storage
    const user = { ...userSubject.value, ...params };
    // localStorage.setItem('user', JSON.stringify(user));

    // publish updated user to subscribers
    userSubject.next(user);
  }
};

// prefixed with underscored because delete is a reserved word in javascript
const _delete = async (id: string) => {
  await fetchWrapper.delete(`${baseUrl}/${id}`);

  // auto logout if the logged in user deleted their own record
  if (id === userSubject.value.id) {
    logout();
  }
};

export const userService = {
  user: userSubject.asObservable(),
  get userValue() {
    return userSubject.value;
  },
  login,
  logout,
  register,
  getAll,
  getById,
  update,
  delete: _delete,
};
