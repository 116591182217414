import {
  CreateFnOObj,
  ProbabilityStockFnoObject,
  fetchWrapper,
} from "src/helpers";
import { graphqlApiCall } from "./graphql.service";

const baseUrl = "/api/probability_stock_fnos";
const columns = [
  "id",
  "cePeFut",
  "entryDate",
  "optionSymbol",
  "buyPrice",
  "stoploss",
  "target",
  "estimatedDuration",
  "orderType",
];

const getAll = async () => {
  const columns = [
    "id",
    "entryDate",
    "optionSymbol",
    "stockSymbol",
    "stockName",
    "buyPrice",
    "cePeFut",
    "lotSize",
    "stoploss",
    "target",
    "ltp",
    "estimatedDuration",
    "variance",
    "currVariance",
    "status",
    "statusUpdatedOn",
    "actualDuration",
    "orderType",
  ];
  const rows = await graphqlApiCall({
    node: "allAppHighProbFnos",
    columns,
    conditions: "orderBy: ENTRY_DATE_DESC",
  });
  return rows.map((row: ProbabilityStockFnoObject) => {
    return {
      id: row.id,
      entryDate: row.entryDate,
      optionSymbol: row.optionSymbol,
      stockSymbol: row.stockSymbol,
      stockName: row.stockName,
      cePeFut: row.cePeFut,
      buyPrice: row.buyPrice,
      lotSize: row.lotSize,
      stoploss: row.stoploss,
      target: row.target,
      ltp: row.ltp,
      diff: parseFloat(String(row.ltp - row.buyPrice)).toFixed(2),
      estimatedDuration: row.estimatedDuration,
      variance: parseFloat(String(row.variance)).toFixed(2),
      currVariance: parseFloat(String(row.currVariance)).toFixed(2),
      status: row.status,
      statusUpdatedOn: row.statusUpdatedOn,
      actualDuration: row.actualDuration,
      orderType: row.orderType,
    };
  });
};

const getById = async (id: string) => {
  const response: CreateFnOObj = await graphqlApiCall({
    node: "appHighProbFnoById",
    columns,
    id,
  });
  const entryDate = new Date(response.entryDate)
    .toLocaleString("sv-SE", { timeZone: "Asia/Kolkata" })
    .split(" ")[0];
  response.entryDate = entryDate;
  return response;
};

const update = async (id: string, params: CreateFnOObj) => {
  return await fetchWrapper.put(`${baseUrl}/${id}`, params);
};

const create = async (probability_stock_fno: CreateFnOObj) => {
  return await fetchWrapper.post(baseUrl, probability_stock_fno);
};

// prefixed with underscored because delete is a reserved word in javascript
const _delete = async (id: string) => {
  const response = await graphqlApiCall({
    node: "deleteAppHighProbFnoById",
    columns: ["deletedAppHighProbFnoId"],
    id,
  });
  return response;
};

export const probabilityStockFnoService = {
  getAll,
  getById,
  create,
  update,
  delete: _delete,
};
