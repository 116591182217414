import { graphqlApiCall } from "./graphql.service";

type StockMaster = {
  nameofCompany: string;
  symbol: string;
};
const getAll = async () => {
  const columns = ["nameofCompany", "symbol"];
  const response: StockMaster[] = await graphqlApiCall({
    node: "allAllstockmasters",
    columns,
    conditions: "orderBy: SYMBOL_ASC",
  });
  return response.map((data) => {
    return {
      NameofCompany: data.nameofCompany,
      Symbol: data.symbol,
    };
  });
};

export const stockMasterService = {
  getAll,
};
