import { ReactNode } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import PropTypes from "prop-types";

interface NavLinkProps {
  href: string;
  exact?: boolean | false;
  children: ReactNode;
  className: string;
}
const NavLink = (navLinkProps: NavLinkProps) => {
  const { children, href, exact, ...props } = navLinkProps;
  const { pathname } = useRouter();
  const isActive = exact ? pathname === href : pathname.startsWith(href);

  if (isActive) {
    props.className += " active underline";
  }

  return (
    <Link href={href} {...props}>
      {children}
    </Link>
  );
};

NavLink.propTypes = {
  href: PropTypes.string.isRequired,
  exact: PropTypes.bool,
};

// NavLink.defaultProps = {
//   exact: false,
// };

export { NavLink };
