/* eslint-disable  @typescript-eslint/no-explicit-any */

import axios from "axios";

const [AUTH_USER, AUTH_PASS] = (
  process.env.NEXT_PUBLIC_BASIC_AUTH || ":"
).split(":");

export const graphqlApiCall = async (props: {
  node: string;
  columns: string[];
  id?: string;
  conditions?: string;
}) => {
  const { node, columns, id, conditions } = props;
  let query: string;
  if (node.endsWith("ById") && id) {
    if (node.startsWith("delete")) {
      query = `mutation { ${node} (input: { id: "${id}" } ) { ${columns.join("\n")} } }`;
    } else {
      query = `query { ${node} (id: "${id}") { ${columns.join("\n")} } }`;
    }
  } else {
    query = `query { ${node} ${conditions ? `(${conditions})` : ""} { edges { node {${columns.join("\n")} } } } }`;
  }
  const token = `${AUTH_USER}:${AUTH_PASS}`;
  const encodedToken = Buffer.from(token).toString("base64");
  const headers = { Authorization: "Basic " + encodedToken };
  const response = await axios.post(
    "/api/graphql",
    { query: query },
    { headers },
  );
  if (node.endsWith("ById") && id) {
    return response.data.data[node];
  } else {
    return response.data.data[node].edges.map(
      (data: { node: any }) => data.node,
    );
  }
};
