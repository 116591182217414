import { TrendingSectorObj, fetchWrapper } from "src/helpers";
import { graphqlApiCall } from "./graphql.service";

const baseUrl = "/api/trending_sectors";

const getAll = async () => {
  const columns = ["id", "name", "active", "priority"];
  const response: TrendingSectorObj[] = await graphqlApiCall({
    node: "allTrendingSectors",
    columns,
  });
  return response;
};
const update = async (url: string, body: TrendingSectorObj[]) => {
  return await fetchWrapper.post(baseUrl + url, body);
};

export const trendingSectorsService = {
  getAll,
  update,
};
