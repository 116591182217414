import { useContext } from "react";
import AppContext from "./AppContext";
import { useRouter } from "next/router";

const SideBar = () => {
  const context = useContext(AppContext);
  const { dashboards, selectedDashboards, setSelectedDashboards } =
    context.state;
  const { push, pathname } = useRouter();

  const onSelect = (id: string) => {
    const selectedIds = selectedDashboards.map((d) => d.id);
    if (selectedIds.includes(id)) {
      const index = selectedIds.indexOf(id);
      selectedIds.splice(index, 1);
    } else {
      selectedIds.push(id);
    }
    if (setSelectedDashboards) {
      setSelectedDashboards(
        dashboards.filter((d) => selectedIds.includes(d.id)),
      );
    }
    if (pathname !== "/") push("/");
  };

  return (
    <div id="sidebar" className="collapse collapse-horizontal hide border-end">
      <div
        id="sidebar-nav"
        className="list-group border-0 rounded-0 text-sm-start min-vh-100"
      >
        <h3 className="p-3 d-none d-sm-block">Dashboards</h3>
        {dashboards
          .filter((d) => !d.disabled)
          .map((d, i) => (
            <a
              id={d.id}
              onClick={() => onSelect(d.id as string)}
              key={"item-" + i}
              className={`list-group-item border-end-0 d-inline-block pointer text-truncate mb-1${selectedDashboards.filter((dash) => dash.id === d.id).length && " active"}`}
              data-bs-parent="#sidebar"
            >
              <span className="ms-1 d-sm-none d-xs-inline">{i + 1}</span>
              <span className="ms-1 d-none d-sm-inline">{d.name}</span>
            </a>
          ))}
      </div>
    </div>
  );
};
export default SideBar;
