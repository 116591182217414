import React, { Dispatch, SetStateAction } from "react";
import { DashboardObject, UserObject } from "src/helpers";

type AppContextType = {
  state: {
    currentUser?: UserObject;
    dashboards: DashboardObject[];
    selectedDashboards: DashboardObject[];
    setShowLoader?: Dispatch<SetStateAction<boolean>>;
    setDashboards?: Dispatch<SetStateAction<DashboardObject[]>>;
    setSelectedDashboards?: Dispatch<SetStateAction<DashboardObject[]>>;
  };
};

const AppContext = React.createContext<AppContextType>({
  state: { dashboards: [], selectedDashboards: [] },
});

export default AppContext;
