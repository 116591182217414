import {
  CreateHpsObj,
  HighProbabilityStockObj,
  fetchWrapper,
} from "src/helpers";
import { graphqlApiCall } from "./graphql.service";

const baseUrl = "/api/probability_stocks";

const getAll = async () => {
  const columns = [
    "id",
    "entryDate",
    "stockSymbol",
    "stockName",
    "buyPrice",
    "highSinceEntry",
    "profitBooked",
    "stopLossPercentage",
    "targetPercentage",
    "ltp",
    "estimatedDuration",
    "variance",
    "currVariance",
    "status",
    "actualDuration",
    "statusUpdatedOn",
  ];
  const rows = await graphqlApiCall({
    node: "allAppHighProbabilityStocks",
    columns,
    conditions: "orderBy: ENTRY_DATE_DESC",
  });
  return rows.map((row: HighProbabilityStockObj) => {
    return {
      id: row.id,
      entryDate: row.entryDate,
      stockSymbol: row.stockSymbol,
      stockName: row.stockName,
      buyPrice: `₹${parseFloat(String(row.buyPrice)).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
      highSinceEntry: `₹${parseFloat(String(row.highSinceEntry)).toLocaleString(
        undefined,
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      )}`,
      maxGain: parseFloat(
        String((row.highSinceEntry / row.buyPrice) * 100 - 100),
      ).toFixed(2),
      profitBooked: row.profitBooked,
      stopLoss: `₹${(
        (row.buyPrice * (100 - row.stopLossPercentage)) /
        100
      ).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
      target: `₹${(
        (row.buyPrice * (100 + row.targetPercentage)) /
        100
      ).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
      ltp: parseFloat(String(row.ltp)).toFixed(2),
      diff: parseFloat(String(row.ltp - row.buyPrice)).toFixed(2),
      estimatedDuration: row.estimatedDuration,
      variance: parseFloat(String(row.variance)).toFixed(2),
      currVariance: parseFloat(String(row.currVariance)).toFixed(2),
      status: row.status,
      actualDuration: row.actualDuration,
      statusUpdatedOn: row.statusUpdatedOn,
    };
  });
};

const addHighProbabiltyStock = async (url: string, body: CreateHpsObj) => {
  return await fetchWrapper.post(baseUrl + url, body);
};

const updateHighProbabiltyStock = async (
  id: string,
  params: Record<string, string | boolean | Date>,
) => {
  return await fetchWrapper.put(`${baseUrl}/${id}`, params);
};

const deleteHighProbabiltyStock = async (id: string) => {
  return await fetchWrapper.delete(`${baseUrl}/${id}`);
};

const getById = async (id: string) => {
  const columns = [
    "id",
    "stockSymbol",
    "entryDate",
    "stopLossPercentage",
    "targetPercentage",
    "estimatedDuration",
  ];
  const stockObj: HighProbabilityStockObj = await graphqlApiCall({
    node: "appHighProbabilityStockById",
    columns,
    id,
  });
  const entryDate = new Date(stockObj.entryDate)
    .toLocaleString("sv-SE", { timeZone: "Asia/Kolkata" })
    .split(" ")[0];
  stockObj.entryDate = entryDate;

  return {
    stockObj: stockObj,
  };
};

const calculate = async (params: Record<string, string>) => {
  return await fetchWrapper.post(`${baseUrl}/calculate`, params);
};

export const probabilityStockService = {
  getAll,
  addHighProbabiltyStock,
  updateHighProbabiltyStock,
  deleteHighProbabiltyStock,
  calculate,
  getById,
};
