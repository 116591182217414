import { DashboardObject, fetchWrapper } from "src/helpers";
import { graphqlApiCall } from "./graphql.service";

const baseUrl = "/api/dashboard";
const columns = ["id", "name", "url", "disabled"];

const getAll = async () => {
  const response: DashboardObject[] = await graphqlApiCall({
    node: "allDashboards",
    columns,
  });
  return response;
};

const getById = async (id: string) => {
  const response: DashboardObject = await graphqlApiCall({
    node: "dashboardById",
    columns,
    id,
  });
  return response;
};

const update = async (id: string, params: DashboardObject) => {
  await fetchWrapper.put(`${baseUrl}/${id}`, params);
};

const register = async (dashboard: DashboardObject) => {
  await fetchWrapper.post(`${baseUrl}/register`, dashboard);
};

// prefixed with underscored because delete is a reserved word in javascript
const _delete = async (id: string) => {
  const response = await graphqlApiCall({
    node: "deleteDashboardById",
    columns: ["deletedDashboardId"],
    id,
  });
  return response;
};

export const dashboardService = {
  getAll,
  getById,
  register,
  update,
  delete: _delete,
};
