import { AppNotifyObj, fetchWrapper } from "src/helpers";

const baseUrl = "/api/app_notify";

const post = async (data: AppNotifyObj) => {
  return await fetchWrapper.post(baseUrl, data);
};

export const appNotifyService = {
  post,
};
