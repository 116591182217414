import { BehaviorSubject } from "rxjs";
import { AlertObj } from "src/helpers";

const alertSubject = new BehaviorSubject<AlertObj | null>(null);

const success = (message: string, showAfterRedirect = false) => {
  alertSubject.next({
    type: "success",
    message,
    showAfterRedirect,
  });
};

const error = (message: string, showAfterRedirect = false) => {
  alertSubject.next({
    type: "danger",
    message,
    showAfterRedirect,
  });
};

// clear alerts
const clear = () => {
  // if showAfterRedirect flag is true the alert is not cleared
  // for one route change (e.g. after successful registration)
  let alert = alertSubject.value;
  if (alert?.showAfterRedirect) {
    alert.showAfterRedirect = false;
  } else {
    alert = null;
  }
  alertSubject.next(alert);
};
export const alertService = {
  alert: alertSubject.asObservable(),
  success,
  error,
  clear,
};
